export const SET_ABOUT = 'SET_ABOUT'
export const SET_TIMES = 'SET_TIMES'
export const SET_CAR_TYPES = 'SET_CAR_TYPES'
export const SET_PACKETS = 'SET_PACKETS'
export const SET_SUB_PACKETS = 'SET_SUB_PACKETS'
export const SET_SERVICES = 'SET_SERVICES'
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS'
export const SET_CHOOSEN_PACKET = 'SET_CHOOSEN_PACKET'
export const SET_CHOOSEN_SUB_PACKET = 'SET_CHOOSEN_SUB_PACKET'
export const SET_BOOKINGS = 'SET_BOOKINGS'
export const SET_NEWS = 'SET_NEWS'
export const SET_SLIDER_PHOTOS = 'SET_SLIDER_PHOTOS'
